// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
@import 'material-icons/iconfont/material-icons.css';
@import url('https://fonts.cdnfonts.com/css/myriad-pro');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ismain-primary: mat-palette($mat-indigo);
$ismain-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ismain-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ismain-theme: mat-light-theme(
  (
    color: (
      primary: $ismain-primary,
      accent: $ismain-accent,
      warn: $ismain-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.is-light-theme {
  @include angular-material-theme($ismain-theme);
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  overflow: hidden;
  overscroll-behavior-y: contain;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@import '~normalize.css';

mat-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface {
  padding: 20px;
}

// Custom header that uses only the Material `font-size` and `font-family`.
.is-font-small {
  font-size: 12px;
}

.is-font-large {
  font-size: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #343434;
  border-radius: 2px;

  &:hover {
    background: #636363;
  }
}

.selected-canvas {
  outline: 2px solid red !important;
}

.selected-canvas:focus {
  outline: 5px solid rgba(51, 230, 221, 0.5) !important;
}

.isbuttonrow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.editordialog .mat-dialog-container {
  padding: 10px;
}


.isbutton {
  flex: 1;
  height: 35px;
  align-items: center;
}

.isbutton-icon {
  align-items: center;
  display: 'block';
  justify-content: center;
  width: 100%;
  height: 100%;
}

.isbuttonbar {
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(15, 30px) 1fr repeat(3, 30px);
  column-gap: inherit;
  grid-template-rows: 30px;
  row-gap: inherit;
}

.isgrid-builder {
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 98%;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 3;
  grid-row-end: 4;
}

.highlightOutline {
  outline: 2px solid lightblue;
}

.invalid {
  color: red;
}

.invalid input {
  border-color: red;
}

.pivot-ident {
  width: 200px;
  background-color: silver;
  color: black;
  padding: 2px 2px 2px 2px;
  height: inherit;
  border-right: solid 1px grey;
}

.pivot-cell {
  width: 100px;
  background-color: white;
  color: black;
  padding: 2px 2px 2px 2px;
  height: inherit;
}

.pivot-total {
  width: 100px;
  background-color: rgb(136, 195, 206);
  color: rgb(43, 41, 41);
  padding: 2px 2px 2px 2px;
  height: inherit;
  text-align: right;
  font-weight: 500;
  font-size: 10px;
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}

input {
  border: lightgrey solid 1px;
}

$spin-speed: 0.5s;

.spinner {
  animation: rotation $spin-speed infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// [is] [_isid='46128'].error {
//   background-color: blue !important
// }